<template>
  <div class="offcanvas__category pb-40">
    <button @click="toggleCategoryActive" class="tp-offcanvas-category-toggle">
      <i class="fa-solid fa-bars"></i>
      {{ $t("All Categories") }}
    </button>
    <div class="tp-category-mobile-menu">
      <nav
        :class="`tp-category-menu-content ${isCategoryActive ? 'active' : ''}`"
      >
        <ul :class="isCategoryActive ? 'active' : ''">
          <li v-for="(item, i) in categories" :key="i" class="has-dropdown">
            <nuxt-link-locale
              class="cursor-pointer"
              :href="`/category/${item.slug}`"
              no-prefetch
            >
              <span v-if="item.default_img">
                <NuxtImg
                  :src="item.default_img"
                  alt="cate img"
                  loading="lazy"
                  style="width: 50px; height: 50px; object-fit: contain"
                />
              </span>
              <span>{{ item.name }}</span>
              <button
                v-if="item.children"
                @click="handleOpenSubMenu(item.name)"
                class="dropdown-toggle-btn"
              >
                <i class="fa-regular fa-angle-right"></i>
              </button>
            </nuxt-link-locale>

            <ul
              v-if="item.children"
              :class="`tp-submenu ${
                openCategory === item.name ? 'active' : ''
              }`"
            >
              <li v-for="(child, i) in item.children" :key="i">
                <nuxt-link-locale
                  :href="`/category/${child.slug}`"
                  no-prefetch
                  class="cursor-pointer"
                  >{{ child.name }}</nuxt-link-locale
                >
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script setup lang="ts">
import category_data from "@/data/category-data";
import { storeToRefs } from "pinia";
import { useHomeStore } from "../../../pinia/useHomeStore";
const props = defineProps<{ productType: string }>();
let isCategoryActive = ref<boolean>(false);

const filterCategories = computed(() => {
  return category_data.filter(
    (c) => c.productType.toLowerCase() === props.productType.toLowerCase()
  );
});
const { categories } = storeToRefs(useHomeStore());
let openCategory = ref<string>("");

const handleOpenSubMenu = (title: string) => {
  if (title === openCategory.value) {
    openCategory.value = "";
  } else {
    openCategory.value = title;
  }
};

const toggleCategoryActive = () => {
  isCategoryActive.value = !isCategoryActive.value;
};
</script>

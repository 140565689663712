<template>
  <div
    :class="`cartmini__area tp-all-font-roboto ${
      cartStore.cartOffcanvas ? 'cartmini-opened' : ''
    }`"
  >
    <div class="cartmini__wrapper d-flex justify-content-between flex-column">
      <div class="cartmini__top-wrapper">
        <div class="cartmini__top p-relative">
          <div class="cartmini__top-title">
            <h4>{{ $t("Shopping cart") }}</h4>
          </div>
          <div class="cartmini__close">
            <button
              @click="cartStore.handleCartOffcanvas"
              type="button"
              class="cartmini__close-btn cartmini-close-btn"
              aria-label="close"
            >
              <i class="fal fa-times"></i>
            </button>
          </div>
        </div>
        <!-- <div class="cartmini__shipping">
          <cart-progress />
        </div> -->
        <div v-if="cart_products.length > 0" class="cartmini__widget">
          <div
            v-for="item in cart_products"
            :key="item.id"
            class="cartmini__widget-item"
          >
            <div class="cartmini__thumb">
              <nuxt-link-locale
                :href="`/product-details/${cartProduct(item).slug}`"
              >
                <img
                  :src="cartProduct(item).main_img?.full_url"
                  alt="cart-img"
                  width="70"
                  height="60"
                />
              </nuxt-link-locale>
            </div>
            <div class="cartmini__content">
              <h5 class="cartmini__title">
                <nuxt-link-locale
                  :href="`/product-details/${cartProduct(item).slug}`"
                >
                  {{ cartProduct(item).name }}
                </nuxt-link-locale>
              </h5>
              <div>
                {{ cartVariation(item) }}
              </div>
              <div class="cartmini__price-wrapper">
                <span
                  v-if="cartProduct(item).old_price"
                  class="cartmini__price"
                >
                  {{
                    formatPrice(
                      (cartProduct(item).old_price * item.quantity).toFixed(2)
                    )
                  }}
                </span>
                <span v-else class="cartmini__price">
                  {{
                    formatPrice((productPrice(item) * item.quantity).toFixed(2))
                  }}
                </span>
                <span class="cartmini__quantity"
                  >{{ " " }}x{{ item.quantity }}</span
                >
              </div>
            </div>
            <a
              @click="cartStore.removeCartProduct(item)"
              class="cartmini__del cursor-pointer"
            >
              <i class="fa-regular fa-xmark"></i>
            </a>
          </div>
        </div>
        <!-- if no item in cart  -->
        <div
          v-if="cart_products.length === 0"
          class="cartmini__empty text-center"
        >
          <NuxtImg
            format="webp"
            loading="lazy"
            src="/img/product/cartmini/empty-cart.webp"
            alt="empty-cart-img"
          />
          <p>{{ $t("Your Cart is empty") }}</p>
          <nuxt-link-locale href="/shop" class="tp-btn">{{
            $t("Go to Shop")
          }}</nuxt-link-locale>
        </div>
      </div>
      <div v-if="cart_products.length > 0" class="cartmini__checkout">
        <div class="cartmini__checkout-title mb-30">
          <h4>{{ $t("Subtotal") }}:</h4>
          <span>{{
            formatPrice(cartStore.totalPriceQuantity.total.toFixed(2))
          }}</span>
        </div>
        <div class="cartmini__checkout-btn">
          <nuxt-link-locale
            href="/cart"
            @click="cartStore.handleCartOffcanvas"
            class="tp-btn mb-10 w-100"
          >
            {{ $t("view cart") }}
          </nuxt-link-locale>
          <nuxt-link-locale
            href="/checkout"
            @click="cartStore.handleCartOffcanvas"
            class="tp-btn tp-btn-border w-100"
          >
            {{ $t("checkout") }}
          </nuxt-link-locale>
        </div>
      </div>
    </div>
  </div>
  <!-- overlay start  -->
  <div
    @click="cartStore.handleCartOffcanvas"
    :class="`body-overlay ${cartStore.cartOffcanvas ? 'opened' : ''}`"
  ></div>
  <!-- overlay end  -->
</template>

<script setup lang="ts">
import { useCartStore } from "@/pinia/useCartStore";
import { useCurrencyStore } from "../../pinia/useCurrencyStore";
import { storeToRefs } from "pinia";
import type { CartItem } from "../../types/cart-type";
const { formatPrice } = useCurrencyStore();
const cartStore = useCartStore();
const { cart_products } = storeToRefs<CartItem[]>(useCartStore());
const { cartProduct } = useCartStore();
const productPrice = (item) => {
  if (item.product) return item.product.price;
  return item.product_variation.price;
};
const cartVariation = (item) => {
  if (item.product_variation) {
    let attributes = item.product_variation.attributes;
    let formattedDetails = "";
    for (let index = 0; index < attributes.length; index++) {
      const element = attributes[index];
      formattedDetails += element.name + ":" + element.values[0].name;
      if (index + 1 != attributes.length) formattedDetails += " | ";
    }

    return formattedDetails;
  }
  return "";
};
</script>

<template>
  <div class="tp-footer-bottom">
    <div class="container">
      <div class="tp-footer-bottom-wrapper">
        <div class="row align-items-center">
          <div class="col-md-6">
            <div class="tp-footer-copyright">
              <p>
                © {{ new Date().getFullYear() }}
                {{ $t("All Rights Reserved | to ") }}
                <a href="https://urusglobal.com/" target="_blank">urusglobal</a>
              </p>
            </div>
          </div>
          <div class="col-md-6">
            <div class="tp-footer-payment text-md-end">
              <p>
                <NuxtImg
                  loading="lazy"
                  format="webp"
                  src="/img/footer/footer-pay.png"
                  alt="pay-img"
                />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<template>
  <div
    :class="`offcanvas__area offcanvas__radius ${
      utilsStore.openMobileMenus ? 'offcanvas-opened' : ''
    }`"
  >
    <div class="offcanvas__wrapper">
      <div class="offcanvas__close">
        <button
          @click="utilsStore.handleOpenMobileMenu()"
          class="offcanvas__close-btn offcanvas-close-btn"
          type="button"
          aria-label="close"
        >
          <svg-close-2 />
        </button>
      </div>
      <div class="offcanvas__content">
        <div
          class="offcanvas__top mb-70 d-flex justify-content-between align-items-center"
        >
          <div class="offcanvas__logo logo">
            <nuxt-link-locale href="/">
              <img src="/img/logo/jumbo-logo.webp" alt="logo" />
            </nuxt-link-locale>
          </div>
        </div>
        <!-- mobile category start -->
        <header-component-mobile-categories :product-type="productType" />
        <!-- mobile category end -->
        <div class="tp-main-menu-mobile fix d-lg-none mb-40">
          <!-- mobile menus start -->
          <header-component-mobile-menus />
          <!-- mobile menus end -->
        </div>

        <div class="offcanvas__contact align-items-center d-none">
          <div class="offcanvas__contact-icon mr-20">
            <span>
              <img src="/img/icon/contact.png" alt="contact_img" />
            </span>
          </div>
          <div class="offcanvas__contact-content">
            <h3 class="offcanvas__contact-title">
              <a href="tel:098-852-987">004524865</a>
            </h3>
          </div>
        </div>
        <div class="offcanvas__btn">
          <nuxt-link-locale href="/contact" class="tp-btn-2 tp-btn-border-2">{{
            $t("Contact Us")
          }}</nuxt-link-locale>
        </div>
      </div>
      <div class="offcanvas__bottom">
        <div
          class="offcanvas__footer d-flex align-items-center justify-content-between"
        >
          <div class="offcanvas__currency-wrapper currency">
            <span
              @click="handleToggleActive('currency')"
              class="offcanvas__currency-selected-currency tp-currency-toggle"
              id="tp-offcanvas-currency-toggle"
              >{{ currentCurrency?.code }}
            </span>
            <ul
              :class="`offcanvas__currency-list tp-currency-list ${
                isToggleActive === 'currency' ? 'tp-currency-list-open' : ''
              }`"
            >
              <li v-for="(currency, index) in currencies" :key="index">
                <a
                  href="javascript:;"
                  @click="setCurrentCurrency(currency)"
                  v-if="currentCurrency.code != currency.code"
                  >{{ currency.code }}</a
                >
              </li>
            </ul>
          </div>
          <div class="offcanvas__select language">
            <div
              class="offcanvas__lang d-flex align-items-center justify-content-md-end"
            >
              <div class="offcanvas__lang-img mr-15">
                <!-- <img src="/img/icon/language-flag.png" alt="language-flag" /> -->
              </div>
              <div class="offcanvas__lang-wrapper">
                <span
                  @click="handleToggleActive('lang')"
                  class="offcanvas__lang-selected-lang tp-lang-toggle"
                  id="tp-offcanvas-lang-toggle"
                  >{{ currentLanguage.name }}
                </span>
                <ul
                  :class="`offcanvas__lang-list tp-lang-list ${
                    isToggleActive === 'lang' ? 'tp-lang-list-open' : ''
                  }`"
                >
                  <li v-for="(language, index) in languages" :key="index">
                    <a
                      href="javascript:;"
                      @click="setCurrentLanguage(language)"
                      v-if="currentLanguage.code != language.code"
                      >{{ language.name }}</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    @click="utilsStore.handleOpenMobileMenu()"
    :class="`body-overlay ${utilsStore.openMobileMenus ? 'opened' : ''}`"
  ></div>
</template>

<script setup lang="ts">
import { useUtilityStore } from "@/pinia/useUtilityStore";
import { ref } from "vue";
import { useCurrencyStore } from "@/pinia/useCurrencyStore";
import { storeToRefs } from "pinia";
import type { ICurrency } from "@/types/currency-type";
import { useHomeStore } from "@/pinia/useHomeStore";
import type { ILanguage } from "@/types/languague-type";
import { useAuthStore } from "@/pinia/useAuthStore";
const { locale, setLocale } = useI18n();
const props = defineProps<{ productType: string }>();
const utilsStore = useUtilityStore();

let isToggleActive = ref<string>("");
// handle active
const handleToggleActive = (type: string) => {
  if (type === isToggleActive.value) {
    isToggleActive.value = "";
  } else {
    isToggleActive.value = type;
  }
};

const { currentCurrency, currencies } = storeToRefs(useCurrencyStore());
const { getHomeData } = useHomeStore();
const { getAllCurrencies } = useCurrencyStore();
const { currentLanguage } = storeToRefs(useUtilityStore());

const languages = ref<ILanguage[]>([
  {
    name: "English",
    code: "en",
  },
  {
    name: "العربية",
    code: "ar",
  },
]);

const setCurrentCurrency = (currency: ICurrency) => {
  currentCurrency.value = currency;
  isToggleActive.value = "";
  getHomeData();
};
const setCurrentLanguage = (language: ILanguage) => {
  currentLanguage.value = language;
  isToggleActive.value = "";
  getHomeData();
  setLocale(language.code);
  let direction = language.code == "ar" ? "rtl" : "ltr";
  useHead({
    htmlAttrs: { dir: direction, lang: language.code },
  });
  //allow rtl when it selected ar
};
const { isAuth, signOut } = useAuthStore();
</script>

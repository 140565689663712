<template>
  <a
    v-for="item in social_links"
    :key="item.id"
    :href="item.link"
    class="mx-1 my-1"
    :aria-label="item.title"
  >
    <i :class="item.icon"></i>
  </a>
</template>

<script setup lang="ts">
import { useHomeStore } from "../../pinia/useHomeStore";
import { storeToRefs } from "pinia";
const { contactInfo } = storeToRefs(useHomeStore());
const social_data: {
  id: number;
  link: string;
  icon: string;
  title: string;
}[] = [];
const social_links = computed(() => {
  return [
    {
      id: 1,
      link: contactInfo?.value?.facebook,
      icon: "fa-brands fa-facebook-f",
      title: "Facebook",
    },
    {
      id: 2,
      link: contactInfo?.value?.twitter,
      icon: "fa-brands fa-twitter",
      title: "Twitter",
    },
    {
      id: 3,
      link: contactInfo?.value?.linkedin,
      icon: "fa-brands fa-linkedin-in",
      title: "Linkedin",
    },
    {
      id: 4,
      link: contactInfo?.value?.instagram,
      icon: "fa-brands fa-instagram",
      title: "Instagram",
    },
    {
      id: 5,
      link: contactInfo?.value?.youtube,
      icon: "fa-brands fa-youtube",
      title: "Youtube",
    },
    {
      id: 6,
      link: contactInfo?.value?.snapchat,
      icon: "fa-brands fa-snapchat",
      title: "Snapchat",
    },
    {
      id: 7,
      link: contactInfo?.value?.pinterest,
      icon: "fa-brands fa-pinterest",
      title: "Pinterest",
    },
    {
      id: 8,
      link: contactInfo?.value?.whatsapp,
      icon: "fa-brands fa-whatsapp",
      title: "Whatsapp",
    },
    {
      id: 9,
      link: contactInfo?.value?.tiktok,
      icon: "fa-brands fa-tiktok",
      title: "Tiktok",
    },
    {
      id: 10,
      link: contactInfo?.value?.telegram,
      icon: "fa-brands fa-telegram",
      title: "Telegram",
    },
  ];
});
</script>

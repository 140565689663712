<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="16"
    viewBox="0 0 30 16"
  >
    <rect x="10" width="20" height="2" fill="currentColor" />
    <rect x="5" y="7" width="25" height="2" fill="currentColor" />
    <rect x="10" y="14" width="20" height="2" fill="currentColor" />
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped></style>

<template>
  <div class="tp-footer-contact">
    <div class="tp-footer-contact-item d-flex align-items-start">
      <div class="tp-footer-contact-icon">
        <span>
          <SvgEmail />
        </span>
      </div>
      <div class="tp-footer-contact-content">
        <p>
          <a :href="`mailto:${contactInfo?.email}`">{{ contactInfo?.email }}</a>
        </p>
      </div>
    </div>
    <div class="tp-footer-contact-item d-flex align-items-start">
      <div class="tp-footer-contact-icon">
        <span>
          <SvgLocation />
        </span>
      </div>
      <div class="tp-footer-contact-content">
        <p>
          <a :href="contactInfo?.google_map_link ?? '#'" target="_blank">
            {{ contactInfo?.address }}
          </a>
        </p>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useHomeStore } from "../../pinia/useHomeStore";
import { storeToRefs } from "pinia";
const { contactInfo } = storeToRefs(useHomeStore());
</script>
